import { Injectable } from '@angular/core';

export interface Token {
  customerID: string;
  sub: string;
  mapID: number;
  nbf: number;
  exp: number;
  iat: number;
  iss: string;
  aud: string;
}

@Injectable({
  providedIn: 'root',
})
export class KeyService {
  getToken() {
    return location.search
      .substring(1)
      .split('&')
      .filter(s => s.startsWith('key='))
      .map(s => s.substring(4))
      .join('');
  }

  getTokenPayload(): Token | null {
    const token = this.getToken();
    if (token) {
      const payload = token.split('.')[1];
      try {
        return JSON.parse(atob(payload));
      } catch (ex) {
        return null;
      }
    }
    return null;
  }
}
